@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@400;700&family=Roboto+Condensed:wght@400;700&display=swap");

body {
  margin: 0;
  padding: 0;
  background-image: linear-gradient(to right top, #d76559, #0052ff);
}

* {
  box-sizing: border-box;
}

html {
  font-family: "Quicksand", sans-serif;
  line-height: 1.5;
  color: #ebe7ef;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  min-height: 80rem;
}

main {
  width: 90%;
  max-width: 65rem;
  margin: auto;
}

.bun-section {
  padding: 1rem;
  margin-bottom: 4rem;
  border-radius: 6px;
  background: linear-gradient(40deg, #0052ff, #a1bfff80);
  filter: drop-shadow(0 12px 10px #0052ff);
}

main h2 {
  text-align: center;
  font-family: "Roboto Condensed", sans-serif;
  margin: 0 0 1rem 0;
  color: #fff;
  font-size: 3rem;
}

main h3 {
  text-align: center;
  font-family: "Roboto Condensed", sans-serif;
  margin: 0 0 2rem 0;
  color: #fff;
  font-size: 2rem;
  padding: 0 1rem;
}

.bun-section ul {
  list-style: none;
  padding: 0.5rem;
  margin: 0;
  display: flex;
  gap: 3rem;
  flex-wrap: wrap;
  justify-content: center;
}

.bun-section li {
  width: 18rem;
  text-align: center;
  background: linear-gradient(45deg, #d76559, #0052ff);

  border-radius: 10px; /* Slightly more rounded corners */
  box-shadow: 0 0 25px rgba(255, 255, 255, 0.3); /* Lighter shadow for a futuristic effect */
  transition: transform 0.4s ease; /* Add a smooth transition effect */
  display: inline-block; /* Display as an inline block to wrap the logo */
  max-width: 400px; /* Increase the maximum width of the price box */
}

.bun-section li:hover {
  transform: scale(1.1); /* Scale up on hover */
}

.bun-section li img {
  padding-top: 0rem;
  border-radius: 10px 10px 0 0;
  width: 100%;
  object-fit: cover;
}

.bun-section li h3 {
  margin: 0.1rem 0;
  font-weight: bold;
  font-size: 1.5rem;
  padding: 0.1rem 0.5rem; /* Increase the padding */
}

.bun-section li p {
  font-size: 1.2rem;
  margin-top: 0;
  padding: 0.2rem 1rem; /* Increase the padding */
}

#examples {
  margin: 3rem auto;
}

#examples h2 {
  margin: 10rem 0rem 3rem 0rem;
  text-align: left;
  color: rgba(233, 230, 230, 0.828);
}

#examples menu {
  margin: 1rem 0;
  padding: 0;
  display: flex;
  gap: 0.5rem;
  list-style: none;
}

#examples menu button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 6px;
  background-color: transparent;
  color: #3f1070;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1.2rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

#examples menu button:hover {
  background-color: #1b082f;
  color: #ebe7ef;
}

#examples menu button.active {
  background-color: #7925d3;
  color: #ebe7ef;
}

#tab-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  border-radius: 6px;
  background-color: #2f1d43;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
}

#tab-content h3 {
  margin: 0;
}

#tab-content code {
  font-size: 1rem;
}
