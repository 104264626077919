.home {
  text-align: center;
  padding: 110px 200px 50px 200px;
  display: flex;
  flex-direction: column; /* Adjust as needed based on your layout */
  align-items: center;
  z-index: 1;
}

.home h1 {
  margin: auto;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 4rem;
  margin: 1rem;
  color: #ffffff;
  filter: drop-shadow(0 12px 10px #0052ff);
}

.home p {
  margin: 1rem;
  font-size: 2rem;
  color: #ffffff;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: bold;
  filter: drop-shadow(0 12px 10px #0052ff);
  max-width: 80%;
}

.home-content {
  max-width: 1800px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dancing-dog {
  height: 150px;
  width: auto;
  object-fit: cover;
  padding: 10px;
}

#video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1; /* Place the video behind other content */
}
