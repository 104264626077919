#footer {
  background: linear-gradient(45deg, #d76559, #0052ff);
  color: white;
  text-align: center;
  padding: 1rem;
}

#footer a {
  color: #fff;
  font-weight: bold;
}
