.game {
    background-color: #1c0c28;
    text-align: center;
    padding: 2rem;
    
    
  }

  .game-img {
    width: 60%;
    height: 80%;
    object-fit: cover;
    z-index: -1; /* Place the video behind other content */
  }